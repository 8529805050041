<template>
  <div>
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h1>
            <i class="bi bi-journal-medical"></i>
            {{ $t('pages.questions.title') }}
          </h1>
        </div>
        <div class="col">
          <ul class="steps">
            <li></li>
            <li></li>

            <li class="active"></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col">
        <div class="alert alert-info  d-flex align-items-center" role="alert">
          <i class="bi bi-info-square me-3" style="font-size: 2rem;" ></i>
          {{ $t('pages.questions.form_prompt') }}
        </div>
        <div class="alert alert-danger" v-if="error" role="alert">
          {{ $t('global.error_occured') }}
        </div>
        <form @submit.prevent="onSubmit">
          <template v-for="question in questions">
            <div class="mb-3" :key="question.id">
              <label class="form-label">{{ question.question }}</label>
              <textarea class="form-control" rows="1" :disabled="loading" v-model="question.answer" required style="resize: none;"></textarea>
            </div>
          </template>
          <div class="button-actions text-end mt-5">
            <button class="btn btn-lg btn-outline-primary" :disabled="loading" type="submit">
              {{ $t('global.continue') }}
              <div class="spinner-border text-primary" v-if="loading" role="status">
                <span class="visually-hidden">{{ $t('global.loading') }}</span>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src


import Config from "@/js/config";


export default {
  name: 'VerifyPage',
  data() {
    return {
      uuid: localStorage.getItem('uuid'),
      loading: true,
      error: false,
      questions: [],
    };
  },
  methods: {
    onSubmit() {

      this.loading = true;
      fetch(`${Config.apiUrl}/patients/questions/${this.uuid}.json`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.questions)
      });

        localStorage.setItem('step', 'interview');
        return this.$router.push({name: 'complete'});

    },
    async loadQuestions() {
      this.loading = true;
      let uuid = localStorage.getItem('uuid');
      const rawResponse = await fetch(`${Config.apiUrl}/patients/questions/${uuid}.json`);
      const content = await rawResponse.json();

      if (content.success) {
        this.questions = content.payload.questions;
      } else {
        this.error = true;
      }
      this.loading = false;
    }
  },
  created() {
    if (!this.uuid) {
      return this.$router.push({name: 'verify'});
    }

    this.loadQuestions();
  }
}
</script>
