<template>
  <div>

    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h1>
            <i class="bi bi-card-checklist"></i>
            {{ $t('pages.verify.title') }}
          </h1>
        </div>
        <div class="col">
          <ul class="steps">
            <li class="active"></li>

            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <form @submit.prevent="onSubmit" >
      <div class="row align-items-center">

        <div class="col">
          <div class="alert alert-info  d-flex align-items-center" role="alert">
            <i class="bi bi-info-square me-3" style="font-size: 2rem;"></i>
            {{ $t('pages.verify.uuid_prompt') }}
          </div>
          <div class="alert alert-danger" v-if="error" role="alert">
            {{ $t('pages.verify.wrong_uuid') }}
          </div>

          <div class="mb-3">
            <input type="text" :disabled="loading" masked="false" v-mask="'###-###-###'" class="form-control form-control-lg" v-model="uuid" required>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" value="" id="flexCheck1" required>
            <i18n tag="label" for="flexCheck1" class="form-check-label" path="pages.verify.terms_agreement">
              <a href="#">{{ $t('pages.verify.tos') }}</a>
            </i18n>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="flexCheck2" value="" required>
            <i18n tag="label" for="flexCheck2" class="form-check-label" path="pages.verify.privacy_policy_agreement">
              <a href="#">{{ $t('pages.verify.privacy_policy') }}</a>
            </i18n>
          </div>
          <div class="button-actions text-end mt-5">
            <button class="btn btn-lg btn-outline-primary" :disabled="loading" type="submit">
              {{ $t('global.continue') }}
              <div class="spinner-border text-primary" v-if="loading" role="status">
                <span class="visually-hidden">{{ $t('global.loading') }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import Config from "@/js/config";

export default {
  name: 'VerifyPage',
  data() {
    return {
      uuid: '',
      loading: false,
      error: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.error = false;

      this.verify();
    },
    async verify() {
      let uuid = this.uuid.replaceAll("-", "");
      const rawResponse = await fetch(`${Config.apiUrl}/patients/verify/${uuid}.json`);
      const content = await rawResponse.json();

      if (content.success) {
        localStorage.setItem('uuid', content.payload.uuid);
        this.$router.push({name: 'interview'});
      } else {
        this.error = true;
      }
      this.loading = false;
    }
  },
  created() {
    localStorage.removeItem('uuid');
    localStorage.setItem('step', 'verify');
  }
}
</script>
