import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './css/app.scss';
import './js/registerServiceWorker';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import messages from './assets/i18n/translations';
import router from './js/routes';
import VueTheMask from 'vue-the-mask'
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueTheMask)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en-GB',
  fallbackLocale: 'pl-PL',
  messages
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
