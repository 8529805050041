<template>
  <div>
    <Loader :active="loading">
      <h3>{{ $t('global.processing') }}</h3>
    </Loader>
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h1>
            <i class="bi bi-heart-pulse"></i>
            {{ $t('pages.symptoms.title') }}
          </h1>
        </div>
        <div class="col">
          <ul class="steps">
            <li></li>
            <li></li>
            <li class="active"></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="alert alert-info  d-flex align-items-center" role="alert">
      <i class="bi bi-info-square me-3" style="font-size: 2rem;"></i>
      {{ $t('pages.symptoms.form_prompt') }}
    </div>
    <div class="alert alert-danger" v-if="error" role="alert">
      {{ $t('pages.symptoms.insufficient_symptoms') }}
    </div>
    <div class="mb-3">
      <vue-tags-input
          v-model="tag"
          add-on-key="[13,',']"
          :tags="symptoms"
          :max-tags="5"
          :placeholder="$t('pages.symptoms.input_placeholder')"
          class="form-control form-control-lg"
          @tags-changed="newTags => symptoms = newTags"
      />
    </div>
    <div class="mb-3">
      {{ $t('pages.symptoms.entered_symptoms') }} <b class="text-success" :class="{'text-danger': symptoms.length  < 3 }">{{ symptoms.length }}</b>
    </div>
    <div class="button-actions text-end mt-5">
      <button class="btn btn-lg btn-outline-primary" @click="onSubmit" :disabled="loading" type="submit">
        {{ $t('global.continue') }}
        <div class="spinner-border text-primary" v-if="loading" role="status">
          <span class="visually-hidden">{{ $t('global.loading') }}</span>
        </div>
      </button>
    </div>

  </div>
</template>

<script>

import VueTagsInput from '@johmun/vue-tags-input';
import Loader from "@/components/Loader";
import Config from "@/js/config";

export default {
  name: 'SymptomsPage',
  data() {
    return {
      uuid: localStorage.getItem('uuid'),
      loading: false,
      error: false,
      tag: '',
      symptoms: []
    };
  },
  components: {
    Loader,
    VueTagsInput
  },
  computed: {
    filteredItems() {
     const items =  this.$t('pages.symptoms.suggestions')
      .filter(i => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1);
     return items.slice(0, 5);
    },
  },
  methods: {
    async onSubmit() {
      this.error = false;

      if (this.symptoms.length < 3) {
        this.error = true;
        return;
      }

      this.loading = true;
      const rawResponse = await fetch(`${Config.apiUrl}/patients/symptoms/${this.uuid}.json`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.symptoms)
      });
      const content = await rawResponse.json();
      if (content.success) {
        localStorage.setItem('step', 'questions');
        return this.$router.push({name: 'questions'});
      }
    }
  },
  created() {
    if (!this.uuid) {
      return this.$router.push({name: 'verify'});
    }

  }
}
</script>
