import Vue from 'vue'
import VueRouter from 'vue-router';
import VerifyPage from '../pages/VerifyPage.vue';
import InterviewPage from '../pages/InterviewPage.vue';
import SymptomsPage from '../pages/SymptomsPage.vue';
import QuestionsPage from '../pages/QuestionsPage.vue';
import CompletePage from '../pages/CompletePage.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'verify',
    component: VerifyPage
  },
  {
    path: '/interview',
    name: 'interview',
    component: InterviewPage
  },
  {
    path: '/symptoms',
    name: 'symptoms',
    component: SymptomsPage
  },
  {
    path: '/questions',
    name: 'questions',
    component: QuestionsPage
  },
  {
    path: '/complete',
    name: 'complete',
    component: CompletePage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router
