<template>
  <transition name="fade">
    <div class="loader" v-if="active">
      <div class="text-center">
        <img src="../assets/rolling.gif">
        <h4 class="mt-3">
          <slot></slot>
        </h4>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    active: Boolean
  }
}
</script>
