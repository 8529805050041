//Test

const Config = {

    // domain: 'http://aisystem-crm.test',
    // apiUrl: 'http://aisystem-crm.test/api',

    domain: 'https://aisystem.ec4.dev',
    apiUrl: 'https://aisystem.ec4.dev/api',

}


export default Config;
